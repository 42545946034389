import { TemplateCategory, StrapiTemplateCategory, CountryInfo } from '../typings';
import { getSupportedLanguage, Languages } from './getAllSupportedLanguages';

// Filter the list of categories that contains templates
export function getCategoriesWithTemplates(categoryArray: StrapiTemplateCategory[]) {
  let filteredCategories = categoryArray;
  filteredCategories = filteredCategories.filter((eachCategory: any) => eachCategory.templates.length > 0);
  return filteredCategories;
}

// Get the list of information for template categories, according to the selected language
export function getTemplateCategoryByLocale(categoryArray: StrapiTemplateCategory[], language: string) {
  const result: TemplateCategory[] = [];
  if (categoryArray.length === 0) return null;

  for (const eachCategory of categoryArray) {
    const foundCategory = !result ? false : result.some((x) => x.name === eachCategory.name);
    if (!foundCategory) {
      // use unique category name as default display name, if localizations not created yet
      let displayName = eachCategory.name;
      if (eachCategory.Localizations && eachCategory.Localizations.length > 0) {
        const localization = eachCategory.Localizations.find((x: any) => x.locale === language);
        if (localization) {
          displayName = localization.displayName;
        }
      }

      const tempArray = {
        name: eachCategory.name,
        icon: eachCategory.icon && eachCategory.icon.localFile ? eachCategory.icon.localFile.childImageSharp.gatsbyImageData : '',
        displayName,
        locale: language
      };
      result.push(tempArray);
    }
  }

  return result;
}

// get the display name of selected filters corresponding to the current language
export function getCategoryDisplayName(filterArray: string[], categoryArray: TemplateCategory[]) {
  const result: string[] = [];
  for (const eachCategory of categoryArray) {
    if (filterArray.includes(eachCategory.name)) {
      result.push(eachCategory.displayName);
    }
  }
  return result;
}

/**
 * @function setLanguageFilterByGeo
 * @description Return the array of initial language filter based on user's country.
 * @param {CountryInfo} country - user's current location
 * @param {TemplateCategory[] | null} templateLanguages - template's languages set in Strapi
 * @returns {string[]} Initial language filter in array
 */
export function setLanguageFilterByGeo(country: CountryInfo, templateLanguages: TemplateCategory[] | null) {
  // Get supported language based on user's country code
  const supportedLang = getSupportedLanguage(country);
  // Find the corresponding name in Strapi
  const result = templateLanguages?.find((templateLang: any) => templateLang.name === supportedLang?.localeName);
  return result ? [result.name] : [Languages.English];
}

/**
 * @function getDefaultFilters
 * @description Get the default filter values.
 * For language filter, default value is English when there is no initial language filter assigned.
 * @param {string[]} initialLangFilter
 * @returns {FilterSort}
 */
export function getDefaultFilters(initialLangFilter: string[] = [Languages.English]) {
  return {
    filters: {
      language: initialLangFilter,
      categories: [],
      trades: []
    },
    sort: 'titleasc'
  };
}
