import { Paper, InputBase, InputAdornment } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { graphql, Link, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { enterToSearch, clickToSearch } from '../utils/common';
import { LocationLanguageContextType } from '../typings';
import { Languages } from '../utils/getAllSupportedLanguages';

interface StrapiBanner {
  strapiId: number;
  Title: string;
  Subtitle: string;
  Link: string;
  locale: string;
  Image: string;
}

interface StrapiHeader {
  SearchLabel: string;
  locale: string;
}

interface BannerQueryProps {
  allStrapiBanners: {
    nodes: StrapiBanner[];
  };
  allStrapiHeader: {
    nodes: StrapiHeader[];
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerSection: {
      position: 'relative',
      textAlign: 'center',
      color: '#fff',
      display: 'grid'
    },
    bannerImage: {
      gridArea: '1/1'
    },
    bannerText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff'
    },
    bannerTitle: {
      fontSize: '2.0243em',
      marginBottom: '0.5rem',
      fontWeight: 500,
      textAlign: 'center',
      lineHeight: '1'
    },
    bannerDesc: {
      textAlign: 'center',
      fontSize: '1.25em',
      lineHeight: '1.2'
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    searchInput: {
      width: '23rem',
      height: '1.375rem',
      display: 'flex',
      margin: '16px 190px 16px 190px',
      padding: '0.5rem 0.8rem',
      backgroundColor: '#fff',
      border: 'solid 1.1px #e0e0e0',
      borderRadius: '4px'
    },
    slides: {
      position: 'relative',
      overflow: 'hidden'
    },
    currentSlide: {
      opacity: '1',
      height: '100%',
      position: 'absolute'
    },
    [theme.breakpoints.up('lg')]: {
      slides: {
        minHeight: '266px'
      }
    },
    [theme.breakpoints.down('lg')]: {
      slides: {
        minHeight: '150px'
      },
      bannerTitle: {
        fontSize: '1.3em'
      },
      bannerDesc: {
        fontSize: '1.1em'
      }
    },
    [theme.breakpoints.down('md')]: {
      slides: {
        minHeight: '160px'
      }
    }
  })
);

const Banner: React.FC<{}> = () => (
  <StaticQuery
    query={graphql`
      query BannerQuery {
        allStrapiBanners {
          nodes {
            strapiId
            Title
            Subtitle
            Link
            locale
            Image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    backgroundColor: "#2b5b94"
                    aspectRatio: 5.44
                    transformOptions: { cropFocus: SOUTH, fit: COVER }
                    quality: 100
                  )
                }
              }
            }
          }
        }
        allStrapiHeader {
          nodes {
            SearchLabel
            locale
          }
        }
      }
    `}
    render={(bannersData: BannerQueryProps) => {
      const [searchFieldValue, setSearchFieldValue] = React.useState('');

      const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
      const language: string = locationLanguage?.language ?? Languages.English;

      const banners = bannersData.allStrapiBanners;
      let filterBanner = banners.nodes;
      filterBanner = filterBanner.filter((eachBanner) => eachBanner.locale.includes(language));

      const header = bannersData.allStrapiHeader.nodes.find((eachLabel) => eachLabel.locale === language);
      const searchLabel = header ? header.SearchLabel : '';

      // Technically we can create multiple banners in Strapi.
      // For now, only one banner with multi-languages
      const firstBanner = filterBanner[0];

      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFieldValue(event.target.value);
      };

      const search = (event: React.KeyboardEvent) => {
        enterToSearch(event, searchFieldValue);
      };

      const handleSearchClick = () => {
        clickToSearch(searchFieldValue);
      };

      const classes = useStyles();

      if (firstBanner) {
        return (
          <div className={classes.slides}>
            <Container maxWidth={false} className={classes.currentSlide} disableGutters key={firstBanner.strapiId}>
              <Link to={firstBanner.Link} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className={classes.bannerSection}>
                  <GatsbyImage
                    alt={firstBanner.Title}
                    // @ts-ignore To disable eslint error - 'localFile' does not exist on type 'string'
                    image={firstBanner.Image.localFile.childImageSharp.gatsbyImageData}
                    className={classes.bannerImage}
                  />
                </div>
              </Link>
              <div className={classes.bannerText}>
                <Typography variant='h1' align='center' className={classes.bannerTitle}>
                  {firstBanner.Title}
                </Typography>
                <div className={classes.searchContainer}>
                  <Paper elevation={0} className={classes.searchInput}>
                    <InputBase
                      onChange={handleSearchChange}
                      fullWidth
                      onKeyDown={search}
                      value={searchFieldValue}
                      placeholder={searchLabel}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='search' edge='end' onClick={handleSearchClick}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ 'aria-label': `${searchLabel}` }}
                    />
                  </Paper>
                </div>
                <Typography variant='h2' align='center' className={classes.bannerDesc}>
                  {firstBanner.Subtitle}
                </Typography>
              </div>
            </Container>
          </div>
        );
      }
      return null;
    }}
  />
);

export default Banner;
