import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFeaturedTemplatesSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainSectionContainer: {
      padding: '1rem 0',
      margin: '1rem 0',
      '& a': {
        textDecoration: 'none'
      },
      '& div': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    mainSectionTitle: {
      fontWeight: 600,
      marginBottom: '1rem',
      fontSize: '1.5em',
      lineHeight: '1.42',
      flex: '1',
      display: 'flex',
      flexDirection: 'column'
    },
    browseBtn: {
      color: '#2864ac',
      fontSize: '0.875em',
      fontWeight: 500,
      margin: '0 1em 1em 1em',
      flex: '0 0 auto',
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    },
    browseBtnMobile: {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      mainSectionTitle: {
        textAlign: 'center',
        fontWeight: 500,
        marginBottom: '1rem',
        fontSize: '1.25em',
        lineHeight: '1.2'
      },
      browseBtn: {
        display: 'none'
      },
      browseBtnMobile: {
        display: 'block',
        textAlign: 'center',
        color: '#2864ac',
        textDecoration: 'none',
        margin: '1rem 0',
        '& p': {
          fontSize: '14px',
          lineHeight: 1.14,
          fontWeight: 500
        }
      }
    },
    templateCard: {
      borderWidth: '0px',
      cursor: 'pointer',
      padding: '0px',
      textAlign: 'left',
      width: '100%',
      fontWeight: 'bold',
      textDecoration: 'none',
      '& div': {
        padding: '1rem'
      },
      '& div h4': {
        fontSize: '1em',
        fontWeight: '500',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        overflow: 'hidden'
      }
    },
    [theme.breakpoints.up('md')]: {
      templateCard: {
        '& div': {
          height: '35px'
        }
      }
    }
  })
);

export default useFeaturedTemplatesSectionStyles;
