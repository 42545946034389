import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useCategorySectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    // general css rules
    categoryContainer: {
      display: 'grid',
      gridTemplateColumns: '18px 1fr',
      gap: '0.5rem',
      padding: '1rem',
      alignItems: 'center',
      '& p': {
        fontSize: '1em'
      }
    },
    mainSectionContainer: {
      padding: '1rem 0',
      margin: '1rem 0',
      '& a': {
        textDecoration: 'none'
      }
    },
    mainSectionTitle: {
      fontWeight: 600,
      marginBottom: '1.5rem',
      fontSize: '1.5em',
      lineHeight: '1.42'
    },
    // Match [0, 900px)
    [theme.breakpoints.down('md')]: {
      categoryContainer: {
        '& p': {
          fontSize: '0.875em'
        }
      },
      mainSectionTitle: {
        textAlign: 'center',
        fontWeight: 500,
        marginBottom: '1rem',
        fontSize: '1.25em',
        lineHeight: '1.2'
      }
    },
    // Match [0, 600px)
    [theme.breakpoints.down('sm')]: {
      categoryContainer: {
        '& p': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }
      }
    }
  })
);

export default useCategorySectionStyles;
