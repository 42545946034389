import * as React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { graphql, useStaticQuery, Link } from 'gatsby';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LocationLanguageContextType, CategorySectionProps } from '../typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import useCategorySectionStyles from '../styles/CategorySection';
import { getTemplateCategoryByLocale, getCategoriesWithTemplates } from '../utils/getFilterOptions';

const templateCategoriesQuery = graphql`
  query CategoriesQuery {
    allStrapiCategories(sort: { fields: rank, order: ASC }) {
      nodes {
        name
        rank
        Localizations {
          displayName
          locale
          localeName
        }
        templates {
          id
        }
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 24, height: 24, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

const CategorySection: React.FC<CategorySectionProps> = ({ header }) => {
  const data = useStaticQuery(templateCategoriesQuery);

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? 'en';
  const { allStrapiCategories } = data;
  const categories = getTemplateCategoryByLocale(getCategoriesWithTemplates(allStrapiCategories.nodes), language);
  const classes = useCategorySectionStyles();

  if (!categories || categories.length === 0) return null;

  return (
    <div className={classes.mainSectionContainer}>
      <Typography variant='body1' className={classes.mainSectionTitle}>
        {header}
      </Typography>
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item xs={6} sm='auto' lg='auto' key={category.name}>
            <Link to={`/browse?filters=category:${category.name}`}>
              <Paper className={classes.categoryContainer}>
                {category.icon ? (
                  <>
                    <GatsbyImage alt={category.displayName} image={category.icon} />
                  </>
                ) : (
                  <>
                    <EqualizerIcon />
                  </>
                )}
                <Typography key={category.name}>{category.displayName}</Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategorySection;
