import * as React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StrapiTemplate, LocationLanguageContextType } from 'typings';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { defaultLocation, getSupportedLanguage } from '../utils/getAllSupportedLanguages';
import useFeaturedTemplatesSectionStyles from '../styles/FeaturedTemplatesSection';

const featuredTemplatesQuery = graphql`
  query FeaturedTemplatesQuery {
    allStrapiTemplate(filter: { isPromoted: { eq: true } }, sort: { order: DESC, fields: updated_at }) {
      nodes {
        Slug
        id
        Title
        template_language {
          name
        }
      }
    }
  }
`;

const FeaturedTemplatesSection: React.FC<any> = ({ homePageContent }) => {
  const data = useStaticQuery(featuredTemplatesQuery);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const country = locationLanguage?.country ?? defaultLocation;

  const maxTemplates = 12;
  const tempaltes = data.allStrapiTemplate.nodes as StrapiTemplate[];
  const initialFeaturedTemplates = tempaltes.slice(0, maxTemplates); // the latest 12 featured templates
  const [promotedTemplates, setPromotedTemplates] = React.useState<StrapiTemplate[]>(initialFeaturedTemplates);

  // Trigger to refresh the featured templates based on current country
  // Note: For EN templates it should show for all EN-speaking countries
  React.useEffect(() => {
    const supportedLang = getSupportedLanguage(country);
    const getPromotedTemplates = tempaltes.filter((template) => template.template_language?.name === supportedLang.localeName);
    const templates = getPromotedTemplates.length > 0 ? getPromotedTemplates : initialFeaturedTemplates;
    setPromotedTemplates(templates.slice(0, maxTemplates));
  }, [country.locationCode]);

  const classes = useFeaturedTemplatesSectionStyles();

  return (
    <div className={classes.mainSectionContainer}>
      <div>
        <Typography variant='body1' className={classes.mainSectionTitle}>
          {homePageContent.FeaturedTemplatesLabel}
        </Typography>
        <Link to='/browse/' className={classes.browseBtn}>
          <Typography variant='body1'>{homePageContent.ViewMoreLabel}</Typography>
        </Link>
      </div>

      <Grid container spacing={3}>
        {promotedTemplates.map((eachTemplate: StrapiTemplate) => (
          <Grid item xs={12} sm={6} md={4} key={eachTemplate.Slug}>
            <Link to={`/template/${eachTemplate.Slug}`} className={classes.templateCard}>
              <Paper>
                <Typography variant='h4' key={eachTemplate.Slug}>
                  {eachTemplate.Title}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Link to='/browse/' className={classes.browseBtnMobile}>
        <Typography variant='body1'>{homePageContent.ViewMoreLabel}</Typography>
      </Link>
    </div>
  );
};

export default FeaturedTemplatesSection;
