import * as React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import useHomeNovadeLiteSectionStyles from '../styles/HomeNovadeLiteSection';
import { StrapiNovadeLiteSection } from '../typings';
import { getLiteAppDataByLocale } from '../utils/common';

const NovadeLiteSection: React.FC<StrapiNovadeLiteSection> = (sectionProps) => {
  const { title, subtitle, signupLabel, signupLink, locale } = sectionProps;

  const [enableIframe, setEnableIframe] = React.useState(false);
  const classes = useHomeNovadeLiteSectionStyles();
  const handleIframe = () => {
    setEnableIframe(true);
  };

  const { iosLink, androidLink } = getLiteAppDataByLocale(locale);
  const novadeLiteYoutubeUrl = 'https://www.youtube.com/embed/T19fYj8RU-I?autoplay=1';

  return (
    <div className={classes.sectionContainerColumns}>
      <Container className={classes.containerColumn}>
        <a href={signupLink}>
          <StaticImage
            layout='fullWidth'
            placeholder='tracedSVG'
            src='../assets/Photo/novade_lite_logo.png'
            alt='Novade Solutions'
            className={classes.logoImage}
          />
        </a>
        <Typography variant='h3' className={`${classes.sectionHeader} ${classes.boldCenterText}`}>
          {title}
        </Typography>
        <Typography variant='body1' className={`${classes.sectionBodyText} ${classes.boldCenterText}`}>
          {subtitle}
        </Typography>
        <div className={classes.centerAlign}>
          <Button variant='contained' color='primary' className={classes.sectionSignUpButton} href={signupLink}>
            {signupLabel}
          </Button>
        </div>
        <div className={`${classes.sectionContainerColumns} ${classes.appDownloadSection}`}>
          <Container className={classes.formControl}>
            <a href={iosLink}>
              <StaticImage
                layout='fullWidth'
                placeholder='tracedSVG'
                src='../assets/Photo/novade-lite-apple-store.png'
                alt='Novade Lite iOS app'
                className={classes.imageCenterAlign}
              />
            </a>
          </Container>
          <Container className={classes.formControl}>
            <a href={androidLink}>
              <StaticImage
                layout='fullWidth'
                placeholder='tracedSVG'
                src='../assets/Photo/novade-lite-google-play-store.png'
                alt='Novade Lite android app'
                className={classes.imageCenterAlign}
              />
            </a>
          </Container>
        </div>
      </Container>
      {!enableIframe ? (
        <Container onClick={handleIframe} className={classes.videoSection}>
          <StaticImage
            layout='fullWidth'
            placeholder='tracedSVG'
            src='../assets/Photo/novade-Lite-illustration-video-thumbnail.jpg'
            alt='Novade Lite'
            className={classes.videoPlayBackground}
          />
          <div className={classes.overlaySection}>
            <StaticImage
              layout='constrained'
              placeholder='tracedSVG'
              src='../assets/Photo/icons8-play-button-circled.png'
              alt='Play - Novade Lite'
              className={classes.playIconImage}
            />
          </div>
        </Container>
      ) : (
        <Container className={classes.containerColumn} data-testid='video'>
          <iframe
            width='626'
            height='352'
            src={novadeLiteYoutubeUrl}
            title={title}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          />
        </Container>
      )}
    </div>
  );
};

export default NovadeLiteSection;
