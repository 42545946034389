import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { globalColors } from './globalColorVariables';

const useHomeNovadeLiteSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldCenterText: {
      textAlign: 'center',
      fontWeight: 500
    },
    centerAlign: {
      textAlign: 'center'
    },
    imageCenterAlign: {
      margin: 'auto'
    },
    formControl: {
      marginBottom: '1rem'
    },
    sectionContainerColumns: {
      padding: '2rem 0',
      display: 'flex'
    },
    containerColumn: {
      padding: '15px'
    },
    logoImage: {
      width: '60%',
      margin: 'auto'
    },
    sectionHeader: {
      fontSize: '2rem',
      color: '#5182BB',
      padding: '0.5rem 1rem'
    },
    sectionBodyText: {
      color: '#5182BB',
      margin: '1em 0'
    },
    sectionSignUpButton: {
      color: 'white',
      textTransform: 'uppercase',
      fontSize: '19px',
      paddingLeft: '65px',
      paddingRight: '65px',
      '&:hover': {
        backgroundColor: globalColors.secondaryColor
      }
    },
    appDownloadSection: {
      margin: '0.5em 1.5em',
      padding: '1em 1.5em'
    },
    overlaySection: {
      opacity: '1',
      transition: '.3s ease',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      height: '100%',
      width: '100%'
    },
    videoSection: {
      cursor: 'pointer',
      position: 'relative',
      width: '100%',
      padding: '15px'
    },
    videoPlayBackground: {
      marginTop: '20px',
      width: '600px'
    },
    playIconImage: {
      width: '5.5em',
      height: '5.5em',
      position: 'absolute',
      left: '50%',
      top: '50%',
      zIndex: 2,
      transform: 'translate(-50%, -50%)'
    },
    // Match [0, 900px)
    [theme.breakpoints.down('md')]: {
      sectionContainerColumns: {
        flexDirection: 'column'
      },
      videoPlayBackground: {
        width: '100% !important'
      },
      sectionHeader: {
        fontSize: '1.25em'
      },
      sectionSignUpButton: {
        fontSize: '1em'
      }
    }
  })
);

export default useHomeNovadeLiteSectionStyles;
